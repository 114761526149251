/* global Component , moment , value */
class orderViewComponentB2C extends Component {

    static name() {
        return "orderViewComponent";
    }

    static componentName() {
        return "orderViewComponent";
    }

    getProps() {
        return  ['renderMenu','renderSearch','headerTitle'];
    }

    data() {
        return {
            selectedOrder:null,
            paymentStatus:null,
            paymentStatusCode: null,
            listMode: true,
            viewLoading:false,
            goBackView:"Orders",
            css: {
                ascendingIcon: 'blue chevron up icon',
                descendingIcon: 'blue chevron down icon',
                table: {
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                }
            },
        };
    }

    getWatch() {
        return {
            'activeFilter': "fetchData",
            "$route.query.order":function (newValue,oldValue) {
                this.loadDetail();
            }
        };
    }

    getComputed() {
        return {
            columns: function (){
                let basicColumns = ["Actions",'SerNr', 'TransDate', 'ContactName', 'OrderStatus'];
                if(this.$store.getters.getPriceToShow)
                    basicColumns.push("Total");
                else{
                    basicColumns.push("SubTotal");
                }
                return basicColumns;
            },
            isLiberator:function (){
                if(this.$store.getters.getIsLogin)
                    return this.$store.state.liberatorCodes.length > 0 || this.$store.state.CenterLiberators.length > 0;
                return false;
            },
            isOnlyModifier:function () {
                if(this.$store.getters.getIsLogin)
                    return this.$store.state.onlyModifiedCodes.length > 0 || this.$store.state.CenterOnlyModifiedCodes.length > 0;
                return false;
            },
            activeFilter:function (){
                return this.$store.getters.getOrderView;
            },
            order_list: function () {
                let orderList = this.$store.getters.getOrderList || [];
                for(let order of orderList) {
                    order.TransDate = moment(order.TransDate,'YYYY-MM-DD').format("DD/MM/YYYY");
                    if (order.ecommerceUpdateDatetime && order.ecommerceUpdateDatetime != "")
                        order.ecommerceUpdateDatetime = moment(order.ecommerceUpdateDatetime).format("DD/MM/YYYY HH:MM:ss");
                    else
                        order.ecommerceUpdateDatetime = "";
                }
                return orderList;
            },
            currentOrder: function () {
                return this.$store.getters.getCurrentOrder;
            },
            options: function (h) {
                let basicOption =  {
                    preserveState: true,
                    filterByColumn: false,
                    filterable: ["SerNr", "TransDate","ContactName"],
                    dateColumns:["TransDate"],
                    dateFormat:["DD/MM/YYYY"],
                    multiSorting: {
                        name: [
                            {
                                column: 'SerNr',
                                matchDir: true
                            },
                            {
                                column: 'TransDate',
                                matchDir: true
                            },
                        ]
                    },
                    headings: {
                        'SerNr': this.tr('Order'),
                        'TransDate': this.tr('Date'),
                        'ContactName': this.tr('Contact'),
                        'OrderStatus': this.tr('Status'),
                        'SubTotal': this.tr('Total') + " (IVA EXC)",
                        'Total': this.tr('Total') + " (IVA INC)",
                        'Actions': this.tr('Actions')
                    },
                    perPage: 25,
                    pagination: {
                        chunk: 20,
                    },
                    templates: {
                        Actions: 'orderActionsComponent',
                        Total:      function (h,row,index){
                            try {
                                return parseFloat(row.Total).toFixed(2);
                            }
                            catch (e) {
                                return value;
                            }
                        },

                    },
                    texts: {
                        count: `Mostrando desde {from} a {to} de {count} ${this.tr('Orders')}|{count} ${this.tr('Orders')}|One ${this.tr('Order')}`,
                        filter:  '',
                        limit: '',
                        filterPlaceholder: '',
                        noResults: this.tr('No orders founds'),
                        page: this.tr('Page') + ":", // for dropdown pagination
                        filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                        loading: this.tr('Loading') + '...', // First request to server
                        defaultOption: 'Select {column}', // default option for list filters,
                    },
                    uniqueKey: "SerNr"
                };


                if(this.$store.getters.getPriceToShow)
                    delete basicOption.headings['SubTotal'];
                else{
                    delete basicOption.headings['Total'];
                }

                return basicOption;
            },
            listTitle: function () {
                switch (this.activeFilter) {
                    case 'awaiting':
                        return this.tr("Orders to Authorize");
                    case 'favorites':
                        return this.tr("Favorities Orders");
                    case 'modifier':
                        return this.tr("Orders to Review");
                    default:
                        return this.tr("Tracking Orders");
                }
            }
        };
    }

    autoRefresh(){
        if(!this.couldown) {
            this.couldown = setTimeout(this.fetchData.bind(this), 10000);
        }
    }

    beforeDestroy() {
        return function () {
            if(this.couldown)
                clearTimeout(this.couldown);
        };
    }

    filterOrders(newFilter) {
        this.$store.dispatch('setOrderView', newFilter);
        //this.activeFilter=newFilter;
        //this.fetchData();
    }

    viewOrder(event) {
        this.$store.dispatch('setOrderView',"all");
        this.selectedOrder = null;
        this.paymentStatus = null;
        this.paymentStatusCode = null;
        this.goBackView = "all";
        if(this.$route.path != '/profile')
            this.$router.push({path: 'profile', params: {}, query:{}});
    }

    viewDetail(salesOrder){
        this.selectedOrder = salesOrder.SerNr;
        this.paymentStatus = salesOrder.PaymentStatus;
        this.paymentStatusCode = salesOrder.PaymentStatusCode;
        this.goBackView = this.activeFilter;
        this.$store.dispatch('setOrderView','orderDetail');
    }

    loadDetail(){
        let orderDetail = null;
        if(this.$route.query.order)
            for(let so of this.order_list)
                if(so.SerNr === this.$route.query.order)
                    orderDetail = so;

        if(orderDetail) {
            this.emitEvent('viewOrderDetail', orderDetail);
        }
    }

    async fetchData(){
        //console.log('Fetch Params', this.activeFilter);
        this.viewLoading = true;
        await this.$store.dispatch('loadOrderHistory', this.activeFilter);
        this.viewLoading = false;
        this.loadDetail();
        this.autoRefresh();
    }

    created() {
        return function () {
            this.fetchData();
            this.subscribeEvent('viewOrderDetail',this.viewDetail);
            this.subscribeEvent('reset-order-view',this.viewOrder);
        };
    }

    getMethods() {
        return {
            autoRefresh:this.autoRefresh,
            filterOrders:this.filterOrders,
            viewOrder: this.viewOrder,
            viewDetail:this.viewDetail,
            loadDetail:this.loadDetail,
            fetchData: this.fetchData,
        };
    }

    getTemplate() {
        return `<div class="orders-list">
                  <div class="container-fluid pt-3 px-0 px-md-3">
                       <div class="col-12 px-1 px-md-3">
                            <template v-if="activeFilter=='favorities'">
                                <favouriteOrderViewComponent></favouriteOrderViewComponent>
                            </template>
                            <template  v-else-if="activeFilter=='orderDetail'" id="OrderDetail" >
                                <orderDetailComponent :SOSerNr="selectedOrder" :paymentStatus="paymentStatus" :paymentStatusCode="paymentStatusCode" :goBackView="goBackView"></orderDetailComponent>
                            </template>
                            <template v-else>
                                <addSOFavModalComponent :from="'so-items'"></addSOFavModalComponent>
                                <template v-if="$store.getters.getAppState=='ready'">
                                  <div class="row justify-content-center">
                                      <div class="col-12 px-0 px-lg-3">
                                        <div id="#orderList">
                                            <h5 class="row title">
                                                <span class="col-12 col-md-4 text-center text-md-left mb-2">{{listTitle}}</span>
                                                <div class="col-12 col-md-8 text-center text-md-right">
                                                    <button key="btn-order-1" v-if="activeFilter!='all'" class="btn secondary-btn all-orders-button"  @click="filterOrders('all')"><span class="icon"><icon class="fa-icon" name="list-ul"></icon></span>{{tr("Tracking Orders")}}</button>
                                                    <button key="btn-order-2" v-if="activeFilter!='favorities'"   class="btn secondary-btn fav-button"  @click="filterOrders('favorities')"><span class="icon"><i class="fa-icon fas fa-star"></i></span>{{tr('Favorites Orders')}}</button>
                                                    <button key="btn-order-3" v-if="isLiberator && activeFilter!='awaiting'" class="btn secondary-btn to-approve-button"  @click="filterOrders('awaiting')" ><span class="icon"><icon class="fa-icon" name="user-lock"></icon></span>{{tr('Orders to Approved')}}</button>
                                                    <button key="btn-order-4" v-if="isOnlyModifier && activeFilter!='modifier'"   class="btn secondary-btn to-review-button"  @click="filterOrders('modifier')"><span class="icon"><icon class="fa-icon" name="user-edit"></icon></span>{{tr('Orders to Review')}}</button>
                                                </div>
                                            </h5>
                                            <form autocomplete="off" onsubmit="return false">
                                                 <div class="container center section white" v-if="viewLoading">
                                                     <div class="row justify-content-center align-items-center profile-loading">
                                                          <div class="col-8 text-center">
                                                              <img class="img-fluid" src="../static/ecommerce/img/lg-spinner-gif.gif" >
                                                              <p class="font-weight-bold">{{tr('Page loading please wait')}}</p>
                                                          </div>
                                                      </div>
                                                 </div>
                                                 <v-client-table class="custom-vue-table"  :key="activeFilter+'orderView-'+UIComponentID" @rowclick="" :columns="columns" :data="order_list" :options="options" :css="css" v-else>
                                                    <div slot="afterFilter" class="VueTables__search-field">
                                                       <div class="input-group-append">
                                                            <span class="input-group-text" id="basic-addon2"><i class="icon fas fa-search"></i></span>
                                                      </div>
                                                    </div>
                                                 </v-client-table>
                                            </form>
                                        </div>
                                    </div>
                                  </div>
                                </template>
                            </template>
                       </div>
                  </div>
               </div>`;
    }
}

orderViewComponentB2C.registerComponent();